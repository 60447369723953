import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import reactCookie from 'react-cookie';
import { stringify } from 'query-string';
import { localStorage } from 'window-or-global';
import findIndex from 'lodash/findIndex';
import uniq from 'lodash/uniq';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import * as pageUtils from 'client-utils/utilities-page';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { openModal, closeModal } from 'shared/components/Modal/actions';
import screenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import {
  updateMiniCartItemCount,
  setRecentlyAddedItemCount,
  showMiniCart,
  showMiniCartV2,
  showInternationalMiniCart,
} from 'storefront/components/Header/MiniCart/miniCart-actions';
import getSelectedCustomizationAllTypesChoice from 'pdp/pages/ProductPage/selectors/getSelectedCustomizationAllTypesChoice';
import getSelectedCustomizationAllTypesValue from 'pdp/pages/ProductPage/selectors/getSelectedCustomizationAllTypesValue';
import getSizeSkus from 'pdp/pages/ProductPage/selectors/getSizeSkus';
import { getBuckleFinishDisplayName } from 'pdp/pages/ProductPage/selectors/getSelectedBuckleFinishIndex';
import { getDynamicImageURLForCustomizedProduct } from 'pdp/components/Monogram/dynamicImageUtils/getDynamicScene7ImageURL';
import { SET_TYPEAHEAD_UTAG } from 'client/common/components/App/app-action-types';
import { setGender } from 'shared/components/App/app-actions';
import {
  WOMEN,
  MEN,
  GENDER_CODE_MEN,
} from 'client/common/constants';
import {
  setSelectedGender,
} from 'client-utils/utilities-gender';
import { updateUcaProfileCountCartItems } from 'client-utils/ucaProfileDataCookies';
import { getSilosDesktop, fetchSilosForNewMobileNav } from 'storefront/components/Navigation/actions-navigation';
import {
  filterLocalCognito,
  filterUserAttributes,
} from 'shared/components/YourNeimans/components/utilities';
import { getGuestTokens, isGuestValid } from '@nmg/auth';
import log from 'server/utilities/logger';
import { MONOGRAM_HEADER } from '../../constants';

export const LOADING_APPOINTMENTS = 'LOADING_APPOINTMENTS';
export const RESOLVED_APPOINTMENTS = 'RESOLVED_APPOINTMENTS';
export const REJECTED_APPOINTMENTS = 'REJECTED_APPOINTMENTS';
export const LOADING_DELIVERY_SHIP = 'LOADING_DELIVERY_SHIP';
export const RESOLVED_DELIVERY_SHIP = 'RESOLVED_DELIVERY_SHIP';
export const REJECTED_DELIVERY_SHIP = 'REJECTED_DELIVERY_SHIP';
export const LOADING_RECOMMENDATIONS = 'LOADING_RECOMMENDATIONS';
export const RESOLVED_RECOMMENDATIONS = 'RESOLVED_RECOMMENDATIONS';
export const REJECTED_RECOMMENDATIONS = 'REJECTED_RECOMMENDATIONS';
export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const RESOLVED_PRODUCT = 'RESOLVED_PRODUCT';
export const REJECTED_PRODUCT = 'REJECTED_PRODUCT';
export const LOADING_PRODUCT_PLA = 'LOADING_PRODUCT_PLA';
export const RESOLVED_PRODUCT_PLA = 'RESOLVED_PRODUCT_PLA';
export const REJECTED_PRODUCT_PLA = 'REJECTED_PRODUCT_PLA';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const LOADING_RELATED_ITEMS = 'LOADING_RELATED_ITEMS';
export const RESOLVED_RELATED_ITEMS = 'RESOLVED_RELATED_ITEMS';
export const REJECTED_RELATED_ITEMS = 'REJECTED_RELATED_ITEMS';
export const RESET_RELATED_ITEMS = 'RESET_RELATED_ITEMS';
export const SET_SELECTED_SIZE_INDEX = 'SET_SELECTED_SIZE_INDEX';
export const SET_QL_SELECTED_SIZE_INDEX = 'SET_QL_SELECTED_SIZE_INDEX';
export const SET_QL_SELECTED_COLOR_INDEX = 'SET_QL_SELECTED_COLOR_INDEX';
export const SET_SELECTED_COLOR_INDEX = 'SET_SELECTED_COLOR_INDEX';
export const SET_SELECTED_COLOR_CODE = 'SET_SELECTED_COLOR_CODE';
export const SET_HOVERED_COLOR_INDEX = 'SET_HOVERED_COLOR_INDEX';
export const SET_QL_HOVERED_COLOR_INDEX = 'SET_QL_HOVERED_COLOR_INDEX';
export const SET_HOVERED_SIZE_INDEX = 'SET_HOVERED_SIZE_INDEX';
export const INCREASE_PRODUCT_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_PRODUCT_QUANTITY = 'DECREASE_QUANTITY';
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_QUANTITY';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const ZOOM_PRODUCT_IMAGE = 'ZOOM_PRODUCT_IMAGE';
export const ZOOM_PRODUCT_IMAGE_QL = 'ZOOM_PRODUCT_IMAGE_QL';
export const UNSET_PRODUCT_VIDEO = 'UNSET_PRODUCT_VIDEO';
export const SET_PRODUCT_VIDEO = 'SET_PRODUCT_VIDEO';
// SET_ACTIVE_MEDIA_INDEX is NOT used in PDP Redesign anymore
export const SET_ACTIVE_MEDIA_INDEX = 'SET_ACTIVE_MEDIA_INDEX';
export const SET_IS_COLOR_CHANGED = 'SET_IS_COLOR_CHANGED';
export const SET_QL_IS_COLOR_CHANGED = 'SET_QL_IS_COLOR_CHANGED';
export const ADD_TO_BAG_ERROR = 'ADD_TO_BAG_ERROR';
export const ADD_TO_OUTFITTING_QL_BAG_ERROR = 'ADD_TO_OUTFITTING_QL_BAG_ERROR';
export const ADD_PRODUCT_TO_BAG = 'ADD_PRODUCT_TO_BAG';
export const PLA_ADD_PRODUCT_TO_BAG = 'PLA_ADD_PRODUCT_TO_BAG';
export const QUICK_LOOK_ADD_PRODUCT_TO_BAG = 'QUICK_LOOK_ADD_PRODUCT_TO_BAG';
export const OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG = 'OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG';
export const BOPS_ERROR = 'BOPS_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_OUTFITTING_QL_ERROR = 'CLEAR_OUTFITTING_QL_ERROR';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_STORE_JSON = 'GET_STORE_JSON';
export const GET_STORES = 'GET_STORES';
export const CLEAR_STORE_JSON = 'CLEAR_STORE_JSON';
export const CLEAR_STORES = 'CLEAR_STORES';
export const LOADING_OOS_RECOMMENDATIONS = 'LOADING_OOS_RECOMMENDATIONS';
export const RESOLVED_OOS_RECOMMENDATIONS = 'RESOLVED_OOS_RECOMMENDATIONS';
export const REJECTED_OOS_RECOMMENDATIONS = 'REJECTED_OOS_RECOMMENDATIONS';
export const RESET_OOS_RECOMMENDATIONS = 'RESET_OOS_RECOMMENDATIONS';
export const LOADING_ADD_TO_BAG = 'LOADING_ADD_TO_BAG';
export const RESOLVED_ADD_TO_BAG = 'RESOLVED_ADD_TO_BAG';
export const REJECTED_ADD_TO_BAG = 'REJECTED_ADD_TO_BAG';
export const SET_REPLENISH_INTERVAL = 'SET_REPLENISH_INTERVAL';
export const SET_BOPS_ERROR_FOR_REPLENISHMENT = 'SET_BOPS_ERROR_FOR_REPLENISHMENT';
export const SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA = 'SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const SET_PERSONALIZATION = 'SET_PERSONALIZATION';
export const SET_SELECTED_CUSTOMIZATIONS = 'SET_SELECTED_CUSTOMIZATIONS';
export const SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES = 'SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES';
export const SET_SELECTED_CUSTOMIZATION_OBJECT = 'SET_SELECTED_CUSTOMIZATION_OBJECT';
export const SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE = 'SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE';
export const SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES = 'SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES';
export const SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES = 'SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES';
export const SET_DYNAMIC_IMAGE_URL = 'SET_DYNAMIC_IMAGE_URL';
export const SET_PDP_PAGINATION_CONTEXT = 'SET_PDP_PAGINATION_CONTEXT';
export const RESOLVED_GROUP = 'RESOLVED_GROUP';
export const SET_HOVERED_BUCKLE_FINISH_INDEX = 'SET_HOVERED_BUCKLE_FINISH_INDEX';
export const SET_SELECTED_BUCKLE_FINISH_INDEX = 'SET_SELECTED_BUCKLE_FINISH_INDEX';
export const SET_ACTIVE_PDP_TAB = 'SET_ACTIVE_PDP_TAB';
export const BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT = 'BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT';
export const LOADING_STYLYZE_RECOMMENDATIONS = 'LOADING_STYLYZE_RECOMMENDATIONS';
export const RESOLVED_STYLYZE_RECOMMENDATIONS = 'RESOLVED_STYLYZE_RECOMMENDATIONS';
export const REJECTED_STYLYZE_RECOMMENDATIONS = 'REJECTED_STYLYZE_RECOMMENDATIONS';
export const LOADING_PRODUCT_RECOMMENDATIONS = 'LOADING_PRODUCT_RECOMMENDATIONS';
export const REJECTED_PRODUCT_RECOMMENDATIONS = 'REJECTED_PRODUCT_RECOMMENDATIONS';
export const REJECTED_MINIFIED_PRODUCT = 'REJECTED_MINIFIED_PRODUCT';
export const RESOLVED_MINIFIED_PRODUCT = 'RESOLVED_MINIFIED_PRODUCT';
export const RESOLVED_RECOMMENDATIONS_MINIFIED_PRODUCT = 'RESOLVED_RECOMMENDATIONS_MINIFIED_PRODUCT';
export const REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT = 'REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT';
export const SET_ACTIVE_STYLE_BOARD = 'SET_ACTIVE_STYLE_BOARD';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SOURCE_QUICK_LOOK = 'SOURCE_QUICK_LOOK';
export const SOURCE_OUTFITTING_QUICK_LOOK = 'SOURCE_OUTFITTING_QUICK_LOOK';
export const RESET_QUICK_LOOK_PRODUCT = 'RESET_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT = 'RESOLVED_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT_PDP = 'RESOLVED_QUICK_LOOK_PRODUCT_PDP';
export const RESET_QUICK_LOOK_PRODUCT_PDP = 'RESET_QUICK_LOOK_PRODUCT_PDP';
export const INCREASE_PRODUCT_QL_QUANTITY = 'INCREASE_QL_QUANTITY';
export const DECREASE_PRODUCT_QL_QUANTITY = 'DECREASE_QL_QUANTITY';
export const UPDATE_PRODUCT_QL_QUANTITY = 'UPDATE_QL_QUANTITY';
export const SET_MOBILENAV_CONTEXT_PATH = 'SET_MOBILENAV_CONTEXT_PATH';
export const SET_PICKUP_STORE = 'SET_PICKUP_STORE';
export const QL_MODAL = 'QL_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export function getRecommendations(id) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_RECOMMENDATIONS });
    const state = getState();
    const PRODUCT_API_TIMEOUT = get(state, 'apiTimeouts.PRODUCT_API_TIMEOUT');
    const { user, session } = state;
    const headers = {
      Cookie: buildCookieString({
        ...session,
        ...user,
      }),
    };
    const requestApi = httpWithLogging(state, PRODUCT_API_TIMEOUT);
    return requestApi.get(`${NMConfig.API_RECOMMENDATIONS}?productId=${id}`, { headers })
      .then((successResponse) => dispatch({
        type: RESOLVED_RECOMMENDATIONS,
        payload: successResponse.data,
      }))
      .catch(() => dispatch({ type: REJECTED_RECOMMENDATIONS }));
  };
}

const getRequestQueryStringForOutFitting = (product, state) => {
  const queryValues = [];
  const selectedColorIndex = get(product, 'options.selectedColorIndex');

  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const selectedColor = get(sizeSkus[0], 'color.name', '');
  const variantIds = product.id + (selectedColor ? `-${selectedColor}` : '');
  const outfittingQueryParams = get(state, 'productCatalog.outfittingConf.config', '');
  queryValues.push(`accId=${NMConfig.OUTFITTING_API_KEY}`);
  if (selectedColor) {
    queryValues.push(`variantIds=${variantIds}`);
  } else {
    queryValues.push(`prodIds=${get(product, 'metadata.pimStyle', '')}`);
  }
  const queryString = queryValues.length > 0
    ? `?${queryValues.join('&')}&${outfittingQueryParams}`
    : `?${outfittingQueryParams}`;
  return queryString;
};

const getRequestQueryStringForRecommendation = (
  product,
  visuallySimilarConfig,
  brand
) => {
  const minProducts = get(visuallySimilarConfig, 'minProducts', 5);
  const queryValues = [
    `minProducts=${minProducts}`,
  ];
  const selectedColorIndex = get(product, 'options.selectedColorIndex');
  const isOosProduct = get(product, 'linkedData.offers.offers[0].availability') === 'OutOfStock';

  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const selectedColor = isOosProduct && brand === 'HC' ? get(product, 'linkedData.offers.offers[0].itemOffered.color', '') : get(sizeSkus[0], 'color.name', '');
  const variantIds = product.id + (selectedColor ? `-${selectedColor}` : '');
  const accId = brand === 'HC' ? NMConfig.STYLYZE_ACCOUNT_ID : NMConfig.OUTFITTING_API_KEY;

  queryValues.push(`accId=${accId}`);
  if (selectedColor) {
    queryValues.push(`variantIds=${variantIds}`);
  } else if (brand === 'HC' && product.isGroup) {
    const grpPimStyles = [];
    const childProducts = get(product, 'childProducts', {});
    Object.keys(childProducts).forEach((key) => {
      const pimStyleId = key !== 'productIds' ? childProducts[key].metadata.pimStyle : '';
      if (pimStyleId) {
        grpPimStyles.push(pimStyleId);
      }
    });
    queryValues.push(`prodIds=${grpPimStyles}`);
  } else {
    queryValues.push(`prodIds=${get(product, 'metadata.pimStyle', '')}`);
  }

  const queryString = brand === 'HC' ? `?${queryValues.join('&')}&channel=SRP` : `?${queryValues.join('&')}`;

  return queryString;
};


const getRequestQueryStringForMinifiedProductDetils = (
  csbList,
  activeStyleBoard,
) => {
  let queryString = '';
  const outfittingChildren = get(csbList[activeStyleBoard], 'children', []);
  outfittingChildren.forEach((item) => {
    if (item) {
      queryString = `${queryString},${item.variantId.split('-')[0]}`;
    }
  });
  return queryString.replace(',', '');
};

export function getMinifiedProductResponseCall(csbList, id) {
  return (dispatch, getState) => {
    const state = getState();
    const activeStyleBoard = (id) || get(state, 'productCatalog.outfitting.activeStyleBoard');
    const currentMinifiedState = get(state, 'productCatalog.outfitting.minifiedProductResponse', []);
    const queryStringProductIds = getRequestQueryStringForMinifiedProductDetils(
      csbList,
      activeStyleBoard,
    );
    const {
      session = '',
    } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const MINIFIED_PRODUCT_DETAIL_API_TIMEOUT = get(state, 'apiTimeouts.MINIFIED_PRODUCT_DETAIL_API_TIMEOUT');
    const requestApi = httpWithLogging(
      state,
      MINIFIED_PRODUCT_DETAIL_API_TIMEOUT,
    );
    const compositeEnabled = get(state, 'abTestsOpt.pdpfoundation.variation', 'pdp_foundation_off') === 'pdp_foundation_on';
    let requestURI = (compositeEnabled)
      ? `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${queryStringProductIds}`
      : `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}?productIds=${queryStringProductIds}`;
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        const styleBoardId = (id) || 0;
        let minifiedResponseStatus = false;
        const minifiedProductInfo = get(successResponse.data, 'minifiedProductInfo', []);
        if (styleBoardId === 0) {
          minifiedResponseStatus = !(minifiedProductInfo.length > 0
          && csbList.length > 0 && csbList[styleBoardId].children
          && (
            csbList[styleBoardId].children.length === minifiedProductInfo.length
          )
          );
        } else if (styleBoardId > 0) {
          minifiedResponseStatus = minifiedProductInfo.length < 5;
        }
        currentMinifiedState.push({
          styleBoardId,
          data: successResponse.data,
          error: minifiedResponseStatus,
        });
        dispatch({
          type: RESOLVED_MINIFIED_PRODUCT,
          payload: {
            data: currentMinifiedState,
            styleBoard: styleBoardId,
            loaded: true,
          },
        });
      })
      .catch(() => dispatch({ type: REJECTED_MINIFIED_PRODUCT }));
  };
}

export function getOutFittingRecommendations(product) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_STYLYZE_RECOMMENDATIONS });
    const state = getState();
    const queryString = getRequestQueryStringForOutFitting(product, state);
    const OUTFITTING_API_TIMEOUT = get(state, 'apiTimeouts.OUTFITTING_API_TIMEOUT');
    const requestApi = httpWithLogging(state, OUTFITTING_API_TIMEOUT);
    return requestApi.get(`${NMConfig.API_PRODUCT_OUTFITTING}${queryString}`)
      .then((successResponse) => {
        dispatch({
          type: RESOLVED_STYLYZE_RECOMMENDATIONS,
          payload: successResponse.data,
        });
        if (!isEmpty(successResponse.data.csbList)) {
          getMinifiedProductResponseCall(
            successResponse.data.csbList
          )(dispatch, getState);
        }
      })
      .catch(() => dispatch({ type: REJECTED_STYLYZE_RECOMMENDATIONS }));
  };
}

const getRequestQueryStringForRecommendations = (
  visuallySimilarLsbs,
) => {
  const outfittingVariants = get(visuallySimilarLsbs[0], 'variants', []);
  const variantIds = outfittingVariants.map((variant) => {
    const [variantId] = variant.variantId.split('-');

    return variantId;
  });

  return uniq(variantIds).join(',');
};

export function getRecommendationsMinifiedProductCall(visuallySimilarLsbs) {
  return (dispatch, getState) => {
    const state = getState();
    const queryStringProductIds = getRequestQueryStringForRecommendations(
      visuallySimilarLsbs,
    );
    const outfittingVariants = get(visuallySimilarLsbs[0], 'variants', []);
    const {
      session = '',
    } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const MINIFIED_PRODUCT_DETAIL_API_TIMEOUT = get(state, 'apiTimeouts.MINIFIED_PRODUCT_DETAIL_API_TIMEOUT');
    const requestApi = httpWithLogging(
      state,
      MINIFIED_PRODUCT_DETAIL_API_TIMEOUT,
    );
    const compositeEnabled = get(state, 'abTestsOpt.pdpfoundation.variation', 'pdp_foundation_off') === 'pdp_foundation_on';
    let requestURI = (compositeEnabled)
      ? `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${queryStringProductIds}`
      : `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}?productIds=${queryStringProductIds}`;
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        const minifiedProductInfo = get(successResponse.data, 'minifiedProductInfo', []);

        if (isEmpty(minifiedProductInfo)) {
          return dispatch({
            type: REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT,
          });
        }

        const recommendationsData = minifiedProductInfo.map((item) => {
          const obj = outfittingVariants.find((el) => el?.variantId.split('-')[0] === item.id);
          // const stylyzeImgUrl = obj?.img_url;
          const stylyzeImgUrl = get(obj, 'img_url', '');
          return { ...item, stylyzeImgUrl };
        });

        return dispatch({
          type: RESOLVED_RECOMMENDATIONS_MINIFIED_PRODUCT,
          payload: recommendationsData,
        });
      })
      .catch(() => dispatch({
        type: REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT,
      }));
  };
}

export function getProductRecommendations(product) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT_RECOMMENDATIONS });
    const state = getState();
    const brand = state.brand_name?.env || 'NM';
    const queryString = getRequestQueryStringForRecommendation(
      product,
      state.visuallySimilar,
      brand
    );
    const OUTFITTING_API_TIMEOUT = get(state, 'apiTimeouts.OUTFITTING_API_TIMEOUT');
    const requestApi = httpWithLogging(state, OUTFITTING_API_TIMEOUT);
    return requestApi.get(`${NMConfig.API_PRODUCT_RECOMMENDATIONS}${queryString}`)
      .then((successResponse) => {
        const visuallySimilarLsbs = get(successResponse.data, 'visuallySimilarLsbs', []);
        const outfittingVariants = get(visuallySimilarLsbs[0], 'variants', []);
        if (!isEmpty(visuallySimilarLsbs) && !isEmpty(outfittingVariants)) {
          return getRecommendationsMinifiedProductCall(
            visuallySimilarLsbs
          )(dispatch, getState);
        }

        return dispatch({ type: REJECTED_PRODUCT_RECOMMENDATIONS });
      })
      .catch(() => dispatch({ type: REJECTED_PRODUCT_RECOMMENDATIONS }));
  };
}

const getRequestQueryString = (
  showPromoPreview,
  promos,
  id,
  focusProductId,
) => {
  const queryValues = [];

  if (focusProductId) {
    queryValues.push(`focusId=${focusProductId}`);
  }
  if (showPromoPreview) {
    if (promos && promos.length) {
      queryValues.push(`personalizedPromos=${promos}&promoPreview=${showPromoPreview}`);
    } else {
      queryValues.push(`promoPreview=${showPromoPreview}`);
    }
  } else if (promos && promos.length) {
    queryValues.push(`personalizedPromos=${promos}`);
  }

  const queryString = queryValues.length > 0 ? `?${queryValues.join('&')}` : '';
  return queryString;
};

export function getProduct(id, queryStringObj) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const PRODUCT_DETAIL_API_TIMEOUT = get(state, 'apiTimeouts.PRODUCT_DETAIL_API_TIMEOUT');
    const {
      session,
      cookies,
      device,
    } = state;
    const isMobile = device?.isMobile;

    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        AGA: get(session, 'AGA', ''),
      }),
    };
    const {
      promos,
    } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
    const focusProductId = get(queryStringObj, 'focusProductId', '');
    const showPromoPreview = get(cookies, 'enablePromoPreview', false);
    const queryString = getRequestQueryString(
      showPromoPreview,
      promos,
      id,
      focusProductId,
    );
    const compositeEnabled = get(state, 'abTestsOpt.pdpfoundation.variation', 'pdp_foundation_off') === 'pdp_foundation_on';
    let requestURI = (compositeEnabled)
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP_COMPOSITE}/${id}${queryString}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${id}${queryString}`;
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    const countryCode = get(state, 'locale.countryCode', 'US');
    const isDomestic = countryCode === 'US';
    if (internationalToggle) {
      requestURI = requestURI.replace(/(.*\/pdp-svc\/nm)\/(us)\/(.*)/, `$1/${countryCode}/$3`);
      if (get(state, 'toggles.PDP_SVC_V2', false)) {
        requestURI = requestURI.replace(/(.*\/pdp-svc\/v2\/nm)\/(us)\/(.*)/, `$1/${countryCode}/$3`);
      }
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }

    const genderToggle = get(state, 'toggles.HP_GENDER', false) && isDomestic;
    const genderCodeToggle = get(state, 'toggles.PDP_GENDER_CODE', false) && genderToggle;
    const referer = get(state, 'session.referer', '');
    const isNMReferrer = referer?.includes('neimanmarcus') || referer?.includes('localhost');

    // eslint-disable-next-line
    const selectedGender = session?.dt_gender || WOMEN;

    const requestApi = httpWithLogging(state, PRODUCT_DETAIL_API_TIMEOUT);
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        const product = successResponse.data || {};
        const type = product.isGroup ? RESOLVED_GROUP : RESOLVED_PRODUCT;
        const { genderCode } = product;
        const currentGender = genderCode === GENDER_CODE_MEN ? MEN : WOMEN;

        dispatch({
          type,
          payload: successResponse.data,
          ftrTgls: {
            dprToggle: get(state, 'toggles.USE_CLOUDINARY_DPR', false),
          },
        });

        if (
          genderCodeToggle
          && !isNMReferrer
          && currentGender !== selectedGender
        ) {
          setSelectedGender(currentGender);
          dispatch(setGender(currentGender));
          if (isMobile) {
            dispatch(fetchSilosForNewMobileNav());
          } else {
            dispatch(getSilosDesktop());
          }
        }
      })
      .catch(() => dispatch({ type: REJECTED_PRODUCT }));
  };
}

export function getRelatedItems(productId, url, referer, userAgent) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_RELATED_ITEMS });
    const state = getState();
    const RECOMMENDATION_API_TIMEOUT = get(state, 'apiTimeouts.RECOMMENDATION_API_TIMEOUT');
    const {
      JSESSIONID,
      DYN_USER_ID,
      TLTSID,
      W2A,
    } = state.session;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    };
    const query = stringify({
      prod_id: productId,
      ptype: 'product',
      ref: referer,
      user_agent: userAgent,
      url,
    });

    const requestApi = httpWithLogging(state, RECOMMENDATION_API_TIMEOUT);
    return requestApi.get(`${NMConfig.API_BLOOMREACH}/bloomreach/recommendations?${query}`, { headers })
      .then((successResponse) => dispatch({
        type: RESOLVED_RELATED_ITEMS,
        payload: successResponse.data,
      })).catch(() => dispatch({ type: REJECTED_RELATED_ITEMS }));
  };
}

export function resetProduct() {
  return (dispatch) => {
    dispatch({ type: RESET_PRODUCT });
    dispatch({ type: RESET_RECOMMENDATIONS });
    dispatch({ type: RESET_OOS_RECOMMENDATIONS });
    dispatch({ type: RESET_RELATED_ITEMS });
  };
}

export function setMobileNavContextPath(path) {
  return (dispatch) => {
    dispatch(
      {
        type: SET_MOBILENAV_CONTEXT_PATH,
        payload: path,
      }
    );
  };
}

export function setPersonalization(productId, value) {
  return (dispatch) => {
    dispatch({
      type: SET_PERSONALIZATION,
      payload: value,
      productId,
    });
  };
}

export function setSelectedCustomizations(productId, value) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS,
      payload: value,
      productId,
    });
  };
}

function initializeSelectedCustomizationObject(
  option,
  inhouseMonogram,
  firstChoiceType,
) {
  const defaultState = {
    id: option.id,
    isValid: false,
    legacyType: option.legacyType,
    selectedValues: '',
    touched: false,
    savedValues: '',
  };
  if (inhouseMonogram && option.legacyType === 'output data') {
    switch (firstChoiceType) {
      case 'NAME':
        // falls through
      case 'SINGLE_INIT':
        return {
          ...defaultState,
          selectedValues: [''],
          savedValues: [''],
        };
      case 'THREE_INIT':
        // falls through
      case 'THREE_INIT_FML':
        return {
          ...defaultState,
          selectedValues: ['', '', ''],
          savedValues: ['', '', ''],
        };
      default:
        return {
          ...defaultState,
          selectedValues: [''],
          savedValues: [''],
        };
    }
  }
  return { ...defaultState };
}

function getSelectedCustomizationsAllTypes(customization) {
  const customizationOptions = get(customization, 'customizationOptions', []);

  const monoStyleCount = customizationOptions.filter((option) => option.legacyType === 'mono style').length;
  const outputDataCount = customizationOptions.filter((option) => option.legacyType === 'output data').length;
  const inhouseMonogram = monoStyleCount === 1 && outputDataCount === 1;
  let firstChoiceType = '';
  if (inhouseMonogram) {
    firstChoiceType = customizationOptions.find((option) => option.legacyType === 'mono style').choices[0].type;
  }

  const selectedCutomizationsAllTypes = customizationOptions.map((option) => (
    initializeSelectedCustomizationObject(
      option,
      inhouseMonogram,
      firstChoiceType,
    )
  ));

  return selectedCutomizationsAllTypes;
}

export function initializeSelectedCustomizationsAllTypes(productId = '') {
  return (dispatch, getState) => {
    const state = getState();
    const singleProduct = get(state, 'productCatalog.product', { isGroup: false });
    const childProducts = get(state, 'productCatalog.group.childProducts', {});
    const groupProduct = get(childProducts, productId, {});
    const product = singleProduct.isGroup ? groupProduct : singleProduct;
    const { customization = {} } = product;

    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: { previous_view_search_submit: 'false' },
    });

    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES,
      payload: getSelectedCustomizationsAllTypes(customization),
      productId,
    });
  };
}

export function setSelectedCustomizationObjectById(productId = '', selectedCustomizationObject) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATION_OBJECT,
      payload: selectedCustomizationObject,
      productId,
    });
  };
}

export function setSelectedCustomizationsTouchedTrue(productId = '') {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE,
      productId,
    });
  };
}

export function setSelectedCustomizationSavedValues(productId = '') {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES,
      productId,
    });
  };
}

export function setSelectedCustomizationSelectedValuesFromSavedValues(productId = '') {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES,
      productId,
    });
  };
}

export function setSelectedIndex(productId, selectedIndex, optionType) {
  return (dispatch, getState) => {
    if (optionType === 'size') {
      dispatch({
        type: SET_SELECTED_SIZE_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type:
         SET_SELECTED_COLOR_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'buckle finish') {
      dispatch({
        type:
         SET_SELECTED_BUCKLE_FINISH_INDEX,
        payload: selectedIndex,
        productId,
      });
    }

    const typeaheadSuggest = get(getState(), 'page.location.query.src', null) === 'suggest';
    const searchSquery = get(getState(), 'page.location.query.squery', null);

    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: {
        previous_view_search_submit: typeaheadSuggest ? 'true' : 'false',
        search_type_ahead: NMConfig?.BRAND_NAME === 'HC' ? 'false' : 'true',
        previous_view_spr_submit: 'true',
        internal_search_term: searchSquery,
        search_function: typeaheadSuggest ? 'product_search' : [undefined],
      },
    });
  };
}

export function setGroupProductSelectedIndex(selectedIndex, optionType, productId = '') {
  return (dispatch, getState) => {
    if (optionType === 'size') {
      dispatch({
        type: SET_SELECTED_SIZE_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_SELECTED_COLOR_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'buckle finish') {
      dispatch({
        type: SET_SELECTED_BUCKLE_FINISH_INDEX,
        payload: selectedIndex,
        productId,
      });
    }

    const typeaheadSuggest = get(getState(), 'page.location.query.src', null) === 'suggest';

    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: {
        previous_view_search_submit: typeaheadSuggest ? 'true' : 'false',
        search_function: typeaheadSuggest ? 'product_search' : [undefined],
      },
    });
  };
}

export function setQLSelectedIndex(productId, selectedIndex, optionType) {
  return (dispatch) => {
    if (optionType === 'size') {
      dispatch({
        type: SET_QL_SELECTED_SIZE_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_QL_SELECTED_COLOR_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
  };
}

export const setSelectedColorCode = (colorCode) => (dispatch) => dispatch({
  type: SET_SELECTED_COLOR_CODE,
  payload: colorCode,
});

export function setHoveredIndex(productId, hoveredIndex, optionType) {
  return (dispatch) => {
    if (optionType === 'size') {
      dispatch({
        type: SET_HOVERED_SIZE_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_HOVERED_COLOR_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === 'buckle finish') {
      dispatch({
        type: SET_HOVERED_BUCKLE_FINISH_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
  };
}

// eslint-disable-next-line max-len
export function setGroupProductHoveredIndex(hoveredIndex, optionType, productId) {
  return (dispatch) => {
    if (optionType === 'size') {
      dispatch({
        type: SET_HOVERED_SIZE_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_HOVERED_COLOR_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === 'buckle finish') {
      dispatch({
        type: SET_HOVERED_BUCKLE_FINISH_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
  };
}

export function setQLHoveredIndex(productId, hoveredIndex, optionType) {
  return (dispatch) => {
    if (optionType === 'color') {
      dispatch({
        type: SET_QL_HOVERED_COLOR_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
  };
}

export function increaseQuantity(productId, value) {
  return (dispatch) => {
    screenReaderAnnouncer(`quantity is ${value + 1}`)(dispatch);
    dispatch({
      type: INCREASE_PRODUCT_QUANTITY,
      payload: value,
      productId,
    });
    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: { previous_view_search_submit: 'false' },
    });
  };
}

export function decreaseQuantity(productId, value) {
  return (dispatch) => {
    screenReaderAnnouncer(`quantity is ${value - 1}`)(dispatch);
    dispatch({
      type: DECREASE_PRODUCT_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function updateQuantity(productId, value) {
  return (dispatch) => {
    screenReaderAnnouncer(`quantity is ${value}`)(dispatch);
    dispatch({
      type: UPDATE_PRODUCT_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function updateGroupProductQuantity(value, productId = '') {
  return (dispatch) => {
    screenReaderAnnouncer(`quantity is ${value}`)(dispatch);
    dispatch({
      type: UPDATE_PRODUCT_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function setDeliveryDate(productId, value) {
  return (dispatch) => dispatch({
    type: SET_DELIVERY_DATE,
    payload: value,
    productId,
  });
}

export function zoomProductImage(productId, images, currentIndex) {
  return (dispatch) => dispatch({
    type: ZOOM_PRODUCT_IMAGE,
    payload: {
      images,
      currentIndex,
    },
    productId,
  });
}

export function zoomProductImageQl(productId, images, currentIndex) {
  return (dispatch) => dispatch({
    type: ZOOM_PRODUCT_IMAGE_QL,
    payload: {
      images,
      currentIndex,
    },
    productId,
  });
}

export function setActiveMediaIndex(productId, index) {
  return (dispatch) => dispatch({
    type: SET_ACTIVE_MEDIA_INDEX,
    payload: index,
    productId,
  });
}

export function setIsColorChanged(productId, isColorChanged) {
  return (dispatch) => dispatch({
    type: SET_IS_COLOR_CHANGED,
    payload: isColorChanged,
    productId,
  });
}

export function setQLIsColorChanged(productId, isColorChanged) {
  return (dispatch) => dispatch({
    type: SET_QL_IS_COLOR_CHANGED,
    payload: isColorChanged,
    productId,
  });
}

export function setProductVideo(productId) {
  return (dispatch) => dispatch({
    type: SET_PRODUCT_VIDEO,
    productId,
  });
}

export function setActivePDPTab(productId, index) {
  return (dispatch) => dispatch({
    type: SET_ACTIVE_PDP_TAB,
    payload: index,
    productId,
  });
}

export function setReplenishInterval(productId, value) {
  return (dispatch) => {
    let newValue = value;

    switch (value) {
      case 1:
        newValue = 30;
        break;
      case 2:
        newValue = 45;
        break;
      case 3:
        newValue = 60;
        break;
      case 4:
        newValue = 90;
        break;
      case 5:
        newValue = 120;
        break;
      default:
        newValue = value;
    }
    dispatch({
      type: SET_REPLENISH_INTERVAL,
      payload: newValue,
      productId,
    });
  };
}

export function unsetProductVideo(productId) {
  return (dispatch) => dispatch({
    type: UNSET_PRODUCT_VIDEO,
    productId,
  });
}

const createGetPreviouslyAddedItemsRequest = (count, showAddToBagModal,
  pricingStyleUptToggle) => {
  const json = JSON.stringify({
    'RWD.modals.miniCart.MiniCartReq': {
      isRWD: true,
      mobileDevice: true,
      commerceItemCount: count,
      atbUptTest: showAddToBagModal,
      pricingStyleUptToggle,
    },
  });
  if (Buffer.byteLength(json) !== json.length) {
    throw new Error(`Json string: '${json}' is malformed.`);
  }
  return `$b64$${Buffer.from(json, 'binary').toString('base64')}`;
};

export const addToBagErrorTypes = {
  selection: 'selection',
  zero_quantity: 'zero_quantity',
  server_error: 'server_error',
  request_exceeds_inventory: 'request_exceeds_inventory',
};

export const getNarvarABTestGroupID = () => 'controlGroup';

const isSourceQuickLook = (state) => get(state, 'productListPage.products.sourceQuickLook', false);
const isSourceOutfittingQuickLook = (state) => get(state, 'productCatalog.outfitting.QLSummary.sourceQuickLook', false);

export function openAddToBagQLModal() {
  return (dispatch) => dispatch(openModal({
    type: 'AddToBagQLModal',
    className: 'add-to-bag-ql-modal',
  }));
}

export function openAddToBagMobile() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const quantityAddedToCart = get(state, 'miniCart.recentlyAddedItemCount');
    const pricingStyleUptToggle = get(state, 'toggles.PRICING_STYLE_UPDATE');
    requestApi
      .post(NMConfig.API_GET_LAST_BAG_ITEM, {
        bid: 'GetFragmentReq',
        data: createGetPreviouslyAddedItemsRequest(quantityAddedToCart, false,
          pricingStyleUptToggle),
      })
      .then((response) => openModal({
        type: 'AddToBagMobileModal',
        className: 'add-to-bag-mobile-modal',
        content: get(response, 'data.MiniCartResp.html'),
      })(dispatch));
    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: { previous_view_search_submit: 'false' },
    });
  };
}

export function openAddToBagModal(showAddToBagModal) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const quantityAddedToCart = get(state, 'miniCart.recentlyAddedItemCount');
    const pricingStyleUptToggle = get(state, 'toggles.PRICING_STYLE_UPDATE');
    requestApi
      .post(NMConfig.API_GET_LAST_BAG_ITEM, {
        bid: 'GetFragmentReq',
        data: createGetPreviouslyAddedItemsRequest(quantityAddedToCart,
          showAddToBagModal, pricingStyleUptToggle),
      })
      .then((response) => openModal({
        type: 'AddToBagModal',
        className: 'atb-modal',
        content: get(response, 'data.MiniCartResp.html'),
      })(dispatch));
  };
}

const isPlaExperience = (state) => {
  const isPlaEnabled = get(state, 'productListingAd.status.enabled', false);
  const ecid = get(state, 'routing.locationBeforeTransitions.query.ecid', '');

  return ecid && isPlaEnabled;
};

export function completeAddToBag(showTrueFitSizeModal, cartData) {
  return (dispatch, getState) => {
    const state = getState();
    const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
    const addToCartV2Toggle = get(state, 'toggles.ADD_TO_CART_V2', false) && isDomestic;
    if (isSourceOutfittingQuickLook(state) || isSourceQuickLook(state)) {
      openAddToBagQLModal()(dispatch);
    } else {
      let showAddToBagModal = false;
      if (get(state, 'abTestsOpt.atbupt.variation', 'a') === 'b') {
        const productCatalog = state.productCatalog || {};
        const { product } = productCatalog;
        showAddToBagModal = !(product.isGroup
          || product.isChanel
          || isPlaExperience(state));
      }
      if (!showAddToBagModal && addToCartV2Toggle) {
        if (pageUtils.isMobile()) {
          openAddToBagMobile()(dispatch, getState);
        } else {
          showMiniCartV2(cartData)(dispatch, getState);
        }
      } else if (!showTrueFitSizeModal && addToCartV2Toggle) {
        openAddToBagModal(showAddToBagModal)(dispatch, getState);
      }
      if (!showAddToBagModal && !addToCartV2Toggle) {
        if (pageUtils.isMobile()) {
          openAddToBagMobile()(dispatch, getState);
        } else {
          showMiniCart()(dispatch, getState);
        }
      } else if (!showTrueFitSizeModal && !addToCartV2Toggle) {
        openAddToBagModal(showAddToBagModal)(dispatch, getState);
      }
    }
  };
}

export function openGwp(gwpProdId, gwpPromoKey) {
  return (dispatch, getState) => {
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: reactCookie.load('JSESSIONID'),
        DYN_USER_ID: reactCookie.load('DYN_USER_ID'),
        TLTSID: reactCookie.load('TLTSID'),
        W2A: reactCookie.load('W2A'),
      }),
    };
    const state = getState();
    const PRODUCT_DETAIL_API_TIMEOUT = get(state, 'apiTimeouts.PRODUCT_DETAIL_API_TIMEOUT');
    const requestApi = httpWithLogging(state, PRODUCT_DETAIL_API_TIMEOUT);

    requestApi.get(`${NMConfig.API_PRODUCT_DETAIL_PDP}/${gwpProdId}`, { headers })
      .then((response) => openModal({
        type: 'GwpMultiSkuModal',
        className: 'gwp-modal',
        props: { gwpProduct: response.data, gwpPromoKey },
        closeDisabled: false,
      })(dispatch))
      .catch(() => {
        completeAddToBag(false)(dispatch, getState);
      });
  };
}

export function openTrueFitMultiSizeModal(prodId) {
  return (dispatch) => dispatch(openModal({
    type: 'TrueFitMultiSizeModal',
    props: {
      product: `${prodId}`,
    },
    className: 'true-fit-multi-size-modal',
  }));
}

export function showAddToBagError(productId, errorMessage) {
  return (dispatch) => dispatch({
    type: ADD_TO_BAG_ERROR,
    payload: { errorMessage },
    productId,
  });
}

export function showGroupProductAddToBagError(errorMessage, productId) {
  return (dispatch) => dispatch({
    type: ADD_TO_BAG_ERROR,
    payload: { errorMessage },
    productId,
  });
}

export function showOutfittingQLAddToBagError(productId, errorMessage) {
  return (dispatch) => dispatch({
    type: ADD_TO_OUTFITTING_QL_BAG_ERROR,
    payload: { errorMessage },
    productId,
  });
}

const generateSiCodeSelections = (product, selectedSku, buckleFinish) => {
  const {
    metadata = {},
  } = product;

  const cmosDetails = `${metadata.cmosCatalogId}_${metadata.cmosItem}_SKU_COLOR`;
  const selectedColorName = get(selectedSku, 'color.name');
  const skuColorSelection = `${cmosDetails}|${selectedColorName}`;
  let siCodeSelections = `${skuColorSelection}`;
  if (buckleFinish) {
    const buckleFinishInput = `FB1|${buckleFinish}`;
    siCodeSelections = `${skuColorSelection}~${buckleFinishInput}`;
  }
  return siCodeSelections;
};

const generateCustomizationObj = (product) => {
  const {
    customization = {},
  } = product;
  const {
    customizationOptions = [],
    behaviorCode = '',
    additionalCharge = 0,
  } = customization;

  const details = {};
  customizationOptions.forEach((customizationOption) => {
    const selectedCustomizationValue = getSelectedCustomizationAllTypesValue(
      product, customizationOption.id
    );
    if (customizationOption.type === 'CHOICE') {
      // eslint-disable-next-line max-len
      const selectedChoice = getSelectedCustomizationAllTypesChoice(product, customizationOption.id);
      details[customizationOption.id] = selectedChoice;
    } else if (Array.isArray(selectedCustomizationValue)) {
      const arrayValue = selectedCustomizationValue.every((value) => value.length === 0) ? '' : selectedCustomizationValue.join('');
      details[customizationOption.id] = arrayValue;
    } else {
      const inputValue = selectedCustomizationValue.length ? selectedCustomizationValue : ' ';
      details[customizationOption.id] = inputValue;
    }
  });

  const customizationOptionInput = {
    monogramType: behaviorCode,
    price: additionalCharge,
    details,
  };

  return customizationOptionInput;
};

const generateSiCodeSelectionsAllTypes = (product, selectedSku) => {
  const {
    metadata = {},
    customization = {},
  } = product;
  const {
    customizationOptions = [],
  } = customization;
  const {
    cmosCatalogId = '',
    cmosItem = '',
  } = metadata;

  const cmosDetails = `${cmosCatalogId}_${cmosItem}_SKU_COLOR`;
  const selectedColorName = get(selectedSku, 'color.name', '');
  const skuColorSelection = selectedColorName ? `${cmosDetails}|${selectedColorName}` : '';

  function shouldAppendTilde(input) {
    return input && !input.endsWith('~');
  }

  let customizationOptionInput = skuColorSelection ? '~' : '';
  customizationOptions.forEach((customizationOption) => {
    const selectedCustomizationValue = getSelectedCustomizationAllTypesValue(
      product, customizationOption.id
    );
    customizationOptionInput += shouldAppendTilde(customizationOptionInput) ? '~' : '';
    if (customizationOption.type === 'CHOICE') {
      // eslint-disable-next-line max-len
      const selectedCustomizationChoice = getSelectedCustomizationAllTypesChoice(product, customizationOption.id);
      customizationOptionInput += `${customizationOption.id}|${selectedCustomizationChoice}`;
    } else if (Array.isArray(selectedCustomizationValue)) {
      const arrayValue = selectedCustomizationValue.every((value) => value.length === 0) ? ' ' : selectedCustomizationValue.join('');
      customizationOptionInput += `${customizationOption.id}|${arrayValue}`;
    } else {
      const inputValue = selectedCustomizationValue.length ? selectedCustomizationValue : ' ';
      customizationOptionInput += `${customizationOption.id}|${inputValue}`;
    }
  });
  return `${skuColorSelection}${customizationOptionInput}`;
};

function handleInternationalAddToBag(requestBody) {
  return (dispatch, getState) => new Promise((resolve) => {
    const state = getState();
    const countryCode = get(state, 'locale.countryCode', 'US');
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
    const localInternationalCart = (() => {
      const localInternationalCartData = localStorage.getItem(intlCartName);
      return localInternationalCartData
        ? JSON.parse(localInternationalCartData)
        : [];
    })();
    const isGroupProduct = get(state, 'productCatalog.product.isGroup', '');
    let inStockKey = '';
    let inStockItem = '';
    let internationalCartData = {};
    if (isGroupProduct) {
      const addedProduct = get(state, 'productCatalog.group.childProducts', '')[requestBody.productId];
      inStockKey = findIndex(addedProduct.skus, { id: requestBody.skuId }, {});
      inStockItem = (inStockKey !== -1) && addedProduct.skus[inStockKey];
      internationalCartData = {
        productQty: requestBody.quantity,
        productId: requestBody.productId,
        skuId: get(inStockItem, 'metadata.cmosSkuId', ''),
        color: requestBody.color,
        size: requestBody.size,
        name: addedProduct.designer.name,
        description: addedProduct.name,
        productUrl: addedProduct.details.canonicalUrl,
        images: addedProduct.media.main,
        price: addedProduct.price,
        inStock: (inStockItem && inStockItem.inStock) ? 'In Stock' : '',
        cmosCatalogId: addedProduct.metadata.cmosCatalogId,
        cmosItem: addedProduct.metadata.cmosItem,
        shippingStatusMessage: inStockItem.shippingStatusMessage,
        stockStatusMessage: inStockItem.stockStatusMessage,
        intlParentheticalAmount: get(addedProduct, 'details.intlParentheticalAmount', 0),
        sku_id: requestBody?.skuId,
        replenishInterval: requestBody?.replenishInterval ? requestBody.replenishInterval : '',
        productSkus: get(addedProduct, 'skus', []),
        isGroupProduct,
      };
    } else {
      inStockKey = findIndex(get(state, 'productCatalog.product.skus', ''), { id: requestBody.skuId }, {});
      inStockItem = (inStockKey !== -1) && get(state, 'productCatalog.product.skus', '')[inStockKey];
      internationalCartData = {
        productQty: requestBody.quantity,
        productId: requestBody.productId,
        skuId: get(inStockItem, 'metadata.cmosSkuId', ''),
        color: requestBody.color,
        size: requestBody.size,
        name: get(state, 'productCatalog.product.designer.name', '') || get(state, 'productCatalog.product.name', ''),
        description: get(state, 'productCatalog.product.name', ''),
        productUrl: get(state, 'productCatalog.product.details.canonicalUrl', ''),
        images: get(state, 'productCatalog.product.media.main', ''),
        price: get(state, 'productCatalog.product.price', ''),
        inStock: (inStockItem && inStockItem.inStock) ? 'In Stock' : '',
        cmosCatalogId: get(state, 'productCatalog.product.metadata.cmosCatalogId', ''),
        cmosItem: get(state, 'productCatalog.product.metadata.cmosItem', ''),
        intlParentheticalAmount: get(state, 'productCatalog.product.details.intlParentheticalAmount', 0),
        sku_id: requestBody?.skuId,
        replenishInterval: requestBody?.replenishInterval ? requestBody.replenishInterval : '',
        productSkus: get(state, 'productCatalog.product.skus', []),
        isGroupProduct,
      };
    }
    localInternationalCart.push(internationalCartData);
    saveToLocalStorage(intlCartName, JSON.stringify(localInternationalCart));
    const totalCartItems = sumBy(localInternationalCart,
      (item) => Number(item.productQty));
    updateMiniCartItemCount(totalCartItems)(dispatch);
    closeModal('QLProductSummary')(dispatch);
    const pageId = get(state, 'page.pageId', '');
    if (pageId === 'PAGE_ID_PLP' || pageId === 'PAGE_ID_SRP') {
      openModal({ type: 'AddToBagQLModal', className: 'add-to-bag-ql-modal' })(dispatch);
    }
    if (pageId === 'PAGE_ID_PDP' || pageId === 'PAGE_ID_PLA') {
      setTimeout(() => {
        showInternationalMiniCart()(dispatch, getState);
        resolve();
      }, 1000);
    }
  });
}

function handleAddToBag(requestBody, onError, isProductPanel) {
  return (dispatch, getState) => {
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const requestApi = httpWithLogging(state, get(apiTimeouts, 'ADD_TO_CART_NODE_CLIENT', NODE_CLIENT_DEFAULT_TIMEOUT));
    const lastRecentlyAddedItemsCount = get(state, 'miniCart.recentlyAddedItemCount');
    dispatch({ type: LOADING_ADD_TO_BAG });
    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: { previous_view_search_submit: 'false' },
    });
    return requestApi.post(`${NMConfig.API_ADD_TO_CART}`, requestBody) // eslint-disable-next-line consistent-return
      .then(({ data }) => {
        dispatch({ type: RESOLVED_ADD_TO_BAG });
        const {
          ProductPageResp = {},
        } = data;
        const {
          addToCartResp = {},
          totalNumberOfItems,
        } = ProductPageResp;
        const {
          gwpShowModal = false,
          gwpProdId,
          gwpPromoKey,
          recentlyAddedItems = lastRecentlyAddedItemsCount + 1,
          recentlyAddedProductIds = requestBody.productId,
          showTrueFitSizeModal,
        } = addToCartResp;
        const productIds = recentlyAddedProductIds.split(',');
        let utagData = JSON.parse(ProductPageResp.utag__data__ajax);
        utagData.cart_total_items = ProductPageResp.totalNumberOfItems;
        const cartPriceAndReqQuantity = {};
        cartPriceAndReqQuantity.quantity = requestBody.quantity;
        cartPriceAndReqQuantity.cart_change_product_price = (
          utagData.cart_change_product_price
          && Number(utagData.cart_change_product_price[0])
        )
          ? utagData.cart_change_product_price[0]
          : null;

        updateUcaProfileCountCartItems(totalNumberOfItems);
        updateMiniCartItemCount(totalNumberOfItems)(dispatch);
        setRecentlyAddedItemCount(recentlyAddedItems)(dispatch);
        let extraParam = {
          trufit_multiplesize_modal: showTrueFitSizeModal,
        };

        let dispatchType;
        if (isPlaExperience(state)) {
          dispatchType = PLA_ADD_PRODUCT_TO_BAG;
        } else if (isSourceQuickLook(state)) {
          dispatchType = QUICK_LOOK_ADD_PRODUCT_TO_BAG;
        } else if (isSourceOutfittingQuickLook(state)) {
          dispatchType = OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG;
          extraParam += {
            color: requestBody.color,
            size: requestBody.size,
            quantity: requestBody.quantity,
            skuId: requestBody.skuId,
          };
        } else {
          dispatchType = ADD_PRODUCT_TO_BAG;
        }

        if (isProductPanel) {
          utagData = {
            ...utagData,
            isYMALProduct: !!requestBody?.isYMALProduct,
            cart_change_bops_flag: 'false',
          };
        }

        dispatch({
          type: dispatchType,
          payload: {
            utagData,
            productIds,
            ...extraParam,
          },
          productId: requestBody.productId,
        });

        if (isProductPanel) {
          return;
        }

        if (gwpShowModal) {
          openGwp(gwpProdId, gwpPromoKey)(dispatch, getState);
        } else {
          if (!showTrueFitSizeModal
            || (showTrueFitSizeModal && !pageUtils.isMobile())
          ) {
            completeAddToBag(showTrueFitSizeModal)(dispatch, getState);
          }
          if (showTrueFitSizeModal) {
            openTrueFitMultiSizeModal(
              requestBody.productId
            )(dispatch, getState);
          } else {
            completeAddToBag(showTrueFitSizeModal)(dispatch, getState);
          }
        }
      })
      .catch(() => {
        dispatch({ type: REJECTED_ADD_TO_BAG });
        const errorMessage = 'Sorry, we had a problem adding items to your shopping bag. Please refresh and try again or call 800-825-8000.';
        if (isProductPanel && onError) {
          onError(errorMessage);
        } else if (!isSourceOutfittingQuickLook(state)) {
          showAddToBagError(requestBody.productId, errorMessage)(dispatch);
        } else {
          showOutfittingQLAddToBagError(
            requestBody.productId,
            errorMessage
          )(dispatch);
        }
        screenReaderAnnouncer(errorMessage)(dispatch);
      });
  };
}

function handleAddToBagV2(requestBody, onError, isProductPanel) {
  return async (dispatch, getState) => {
    const state = getState();
    let token;
    let isValidUser = false;
    let sub;

    try {
      const data = await Auth.currentSession();
      token = data.getIdToken().getJwtToken();
      isValidUser = true;
    } catch (e) {
      try {
        await isGuestValid(state?.['env_name']?.env, NMConfig.BRAND_NAME);
      } catch (error) {
        log.error('Error  adding items to bag', error);
      }
    }

    if (!token) {
      const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
      token = AccessToken;
      sub = Sub;
    }

    const apiTimeouts = get(state, 'apiTimeouts', {});
    const requestApi = httpWithLogging(
      state,
      get(apiTimeouts, 'ADD_TO_CART_V2_NODE_CLIENT', 20000),
    );
    const lastRecentlyAddedItemsCount = get(
      state,
      'miniCart.recentlyAddedItemCount',
    );

    const userData = filterLocalCognito('userData');

    const webProfileId = isValidUser
      ? filterUserAttributes(userData, 'preferred_username')
      : sub;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const sourceApp = NMConfig.BRAND_NAME === 'HC' ? 'WH' : 'NMG';

    const body = {
      ...(requestBody?.deliveryDate)
        && { perishableDeliveryByDate: requestBody?.deliveryDate },
      ...(requestBody?.replenishInterval)
        && { replenishmentInterval: requestBody?.replenishInterval },
      ...(requestBody?.customization)
        && { customization: requestBody?.customization },
      productId: requestBody?.productId,
      skuId: requestBody?.skuId,
      quantity: requestBody?.quantity,
      sourceApp,
    };

    dispatch({ type: LOADING_ADD_TO_BAG });
    dispatch({
      type: SET_TYPEAHEAD_UTAG,
      payload: { previous_view_search_submit: 'false' },
    });

    return requestApi
      .post(`${NMConfig.API_ADD_TO_CART_V2}/${webProfileId}`, body, {
        headers,
      }) // eslint-disable-next-line consistent-return
      .then(({ data: cartData }) => {
        dispatch({ type: RESOLVED_ADD_TO_BAG });
        const { shoppingCart = {}, promotions = {} } = cartData;
        const totalNumberOfItems = shoppingCart?.totalQuantity;
        const recentlyAddedItems = lastRecentlyAddedItemsCount + 1;
        const recentlyAddedProductIds = requestBody.productId;
        const productIds = recentlyAddedProductIds.split(',');
        const showTrueFitSizeModal = false;
        const gwpData = Object.fromEntries(
          Object.entries(promotions).filter(([, promo]) => (
            promo?.promoClass === 'GIFT_WITH_PURCHASE'
            && promo?.promoStatus === 'NOT_SELECTED'
          )),
        );
        // const [gwpPromoKey] = Object.keys(gwpData);
        const gwpPromoKey = false;
        const gwpProdId = gwpData[gwpPromoKey]?.promotionalProducts?.productId;

        const getRecentItem = (items) => (
          items.reduce((prev, curr) => {
            const currItem = moment(curr.addedToCartDate).valueOf();
            const prevItem = moment(prev.addedToCartDate).valueOf();
            return prevItem > currItem ? prev : curr;
          }) || {}
        );

        const items = shoppingCart?.commerceItems?.shipments[0]?.items;
        const shipmentType = items.map(
          (item) => item?.shipmentType === 'CURBSIDE_PICKUP',
        );
        const recentItem = getRecentItem(items);
        let utagData = {
          ajax_response_id: 'MiniCartResp',
          cart_change_bops_flag: shipmentType.some((item) => item).toString(),
          cart_change_nmo_sku_id: [recentItem?.skuId],
          cart_change_product_catalog_id: [
            recentItem?.productDetails?.cmosCatalogId,
          ],
          cart_change_product_cmos_item: [recentItem?.productDetails?.cmosItem],
          cart_change_product_cmos_sku: [recentItem?.currentSku?.cmosSkuId],
          cart_change_product_name: [recentItem?.productDetails?.displayName],
          cart_change_product_price: [
            `${recentItem?.productDetails?.retailPrice}`,
          ],
          cart_change_product_quantity: [`${requestBody?.quantity}`],
          cart_change_product_replenish_time: [
            recentItem?.replenishInterval?.toString() || '',
          ],
          cart_total_items: totalNumberOfItems,
        };

        updateUcaProfileCountCartItems(totalNumberOfItems);
        updateMiniCartItemCount(totalNumberOfItems)(dispatch);
        setRecentlyAddedItemCount(recentlyAddedItems)(dispatch);
        let extraParam = {
          trufit_multiplesize_modal: showTrueFitSizeModal,
        };

        let dispatchType;
        if (isPlaExperience(state)) {
          dispatchType = PLA_ADD_PRODUCT_TO_BAG;
        } else if (isSourceQuickLook(state)) {
          dispatchType = QUICK_LOOK_ADD_PRODUCT_TO_BAG;
        } else if (isSourceOutfittingQuickLook(state)) {
          dispatchType = OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG;
          extraParam += {
            color: requestBody.color,
            size: requestBody.size,
            quantity: requestBody.quantity,
            skuId: requestBody.skuId,
          };
        } else {
          dispatchType = ADD_PRODUCT_TO_BAG;
        }

        if (isProductPanel) {
          utagData = {
            ...utagData,
            isYMALProduct: !!requestBody?.isYMALProduct,
            cart_change_bops_flag: 'false',
          };
        }

        dispatch({
          type: dispatchType,
          payload: {
            isCartAdapter: true,
            utagData,
            productIds,
            ...extraParam,
          },
          productId: requestBody.productId,
        });

        if (isProductPanel) {
          return;
        }

        if (gwpPromoKey) {
          openGwp(gwpProdId, gwpPromoKey)(dispatch, getState);
        } else {
          if (
            !showTrueFitSizeModal
            || (showTrueFitSizeModal && !pageUtils.isMobile())
          ) {
            completeAddToBag(
              showTrueFitSizeModal,
              cartData
            )(dispatch, getState);
          }
          if (showTrueFitSizeModal) {
            openTrueFitMultiSizeModal(requestBody.productId)(
              dispatch,
              getState,
            );
          } else {
            completeAddToBag(
              showTrueFitSizeModal,
              cartData
            )(dispatch, getState);
          }
        }
      })
      .catch(() => {
        dispatch({ type: REJECTED_ADD_TO_BAG });
        const errorMessage = 'Sorry, we had a problem adding items to your shopping bag. Please refresh and try again or call 800-825-8000.';
        if (isProductPanel && onError) {
          onError(errorMessage);
        } else if (!isSourceOutfittingQuickLook(state)) {
          showAddToBagError(requestBody.productId, errorMessage)(dispatch);
        } else {
          showOutfittingQLAddToBagError(
            requestBody.productId,
            errorMessage
          )(dispatch);
        }
        screenReaderAnnouncer(errorMessage)(dispatch);
      });
  };
}

export function addToBag(product, selectedSku, skuMediaUrl, storeNumber = '', onError, isProductPanel = false) {
  return (dispatch, getState) => {
    const state = getState();
    const buckleFinish = getBuckleFinishDisplayName(state);
    const PDP_TRUEFIT_SIZE_MODEL = get(state, 'toggles.TRUEFIT_SIZE_MODAL', false);
    const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
    const addToCartV2Toggle = get(state, 'toggles.ADD_TO_CART_V2', false) && isDomestic;
    const {
      id: productId,
      quantity,
      replenishInterval,
      deliveryDate,
      isPersonalizationSelected,
      productFlags = {},
      isYMALProduct,
    } = product;
    const requestBody = {
      productId,
      quantity,
      replenishInterval,
      storeId: storeNumber, // ATG requires store *numbers* for the `storeId` argument
      skuId: selectedSku.id,
      size: get(selectedSku, 'size.name'),
      color: get(selectedSku, 'color.name'),
      deliveryDate,
      narvarAbTestId: getNarvarABTestGroupID(state),
      isYMALProduct,
    };
    if (skuMediaUrl !== '') {
      requestBody.skuMediaUrl = skuMediaUrl;
    }
    const isGroup = get(state, 'productCatalog.product.isGroup', false);
    if (!isGroup && typeof $ !== 'undefined' && PDP_TRUEFIT_SIZE_MODEL) {
      const querySelector = `div#${productId} .tfc-fitrec-result .tfc-popup-click-open`;
      requestBody.isTrueFitEnabled = ($(querySelector).length !== 0);
    }
    if (addToCartV2Toggle) {
      if (isPersonalizationSelected) {
        requestBody.customization = generateCustomizationObj(product);
        if (productFlags.previewSupported) {
          requestBody.skuMediaUrl = getDynamicImageURLForCustomizedProduct(
            product,
            75,
            94,
          );
        }
      }
      if (buckleFinish) {
        requestBody.siCodeSelections = generateSiCodeSelections(
          product,
          selectedSku,
          buckleFinish,
        );
      }
    } else {
      if (isPersonalizationSelected) {
        requestBody.isMonogramSelected = true;
        requestBody.siCodeSelections = generateSiCodeSelectionsAllTypes(
          product,
          selectedSku,
        );
        if (productFlags.previewSupported) {
          requestBody.skuMediaUrl = getDynamicImageURLForCustomizedProduct(
            product,
            75,
            94,
          );
        }
      }
      if (buckleFinish) {
        requestBody.isMonogramSelected = true;
        requestBody.siCodeSelections = generateSiCodeSelections(
          product,
          selectedSku,
          buckleFinish,
        );
      }
    }
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    // eslint-disable-next-line no-nested-ternary
    return internationalToggle && !isDomestic
      ? handleInternationalAddToBag(requestBody)(dispatch, getState)
      : addToCartV2Toggle
        ? handleAddToBagV2(
          requestBody,
          onError,
          isProductPanel
        )(dispatch, getState)
        : handleAddToBag(
          requestBody,
          onError,
          isProductPanel
        )(dispatch, getState);
  };
}

export function addGiftToBag(productId, promoKey, color = 'ANR', size = 'ANR', quantity = 1) {
  return (dispatch, getState) => {
    const requestBody = {
      productId,
      quantity,
      promoKey,
      size,
      color,
    };
    handleAddToBag(requestBody)(dispatch, getState);
  };
}

export function openBops(product, selectedSku, skuMediaUrl) {
  return (dispatch) => (
    openModal({
      type: 'BopsModalOptimized',
      props: { product, selectedSku, skuMediaUrl },
      className: 'bops-modal-optimized',
      adaProps: {
        labelledby: 'storeSearchLabel',
        describedby: 'storeSearchDesc',
      },
    })(dispatch)
  );
}

export function openReplenishment() {
  return (dispatch, getState) => openModal({
    type: 'ReplenishmentModal',
    url: '/category/product/r_replenishmentFAQ.html',
    className: 'replenishment-modal',
    adaProps: {
      role: '',
    },
  })(dispatch, getState);
}

export function openMonogram(product) {
  return (dispatch) => (
    openModal({
      type: 'MonogramModalDropship',
      props: { product },
      className: 'monogram-modal',
      headerText: MONOGRAM_HEADER,
    })(dispatch)
  );
}

export function openAppointments(handleStoreChange) {
  return (dispatch) => (
    openModal({
      type: 'AppointmentsModal',
      props: { handleStoreChange },
      className: 'appointments-modal',
      headerText: 'Choose a store',
    })(dispatch)
  );
}

export function getAppointments(zip, day, hour) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_APPOINTMENTS });
    const state = getState();
    const timeout = get(state, 'apiTimeouts.APPOINTMENTS_API_TIMEOUT', 10000);
    const requestApi = httpWithLogging(state, timeout);

    return requestApi.post(
      `${NMConfig.API_APPOINTMENTS}?zipCode=${zip}&day=${day}&hour=${hour}`
    ).then((successResponse) => {
      dispatch({ type: RESOLVED_APPOINTMENTS });
      dispatch({
        type: GET_APPOINTMENTS,
        payload: successResponse.data,
      });
    }).catch(() => {
      dispatch({ type: REJECTED_APPOINTMENTS });
    });
  };
}

export function getDeliveryShipOptions(
  zipCode = '',
  skuIds = [],
  legacyStoreId,
) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_SHIP });
    const state = getState();
    const timeout = get(state, 'apiTimeouts._DELIVERY_SHIP_API_TIMEOUT', 10000);
    const requestApi = httpWithLogging(state, timeout);

    return requestApi
      .post(`${NMConfig.API_DELIVERY_SHIP}`, {
        zipCode,
        skuIds,
        legacyStoreId,
      })
      .then((successResponse) => {
        dispatch({
          type: RESOLVED_DELIVERY_SHIP,
          payload: successResponse.data,
        });
      })
      .catch(() => {
        dispatch({ type: REJECTED_DELIVERY_SHIP });
      });
  };
}

export function getStoreJSON(storeNumber, distance) {
  return (dispatch) => {
    axios.get(`https://stores.neimanmarcus.com/info/${storeNumber}.json`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_STORE_JSON,
            payload: {
              data: response.data,
              distance,
            },
          });
        }
      });
  };
}

export function getStores(freeFormAddress, skuId, quantity) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    requestApi.get(`${NMConfig.API_STORE}?brandCode=${NMConfig.BRAND_NAME.toLowerCase()}&freeFormAddress=${freeFormAddress}&skuId=${skuId}&quantity=${quantity}`)
      .then((response) => {
        dispatch({
          type: GET_STORES,
          payload: {
            stores: response.data,
            status: 200,
          },
        });
        const cookieDate = new Date();
        cookieDate.setYear(cookieDate.getFullYear() + 1);
        reactCookie.save('bopsSearchTerm', freeFormAddress, { path: '/', expires: cookieDate });
      })
      .catch((error) => {
        dispatch({
          type: GET_STORES,
          payload: {
            stores: [],
            status: error.response.data.upstreamStatusCode
              || error.response.status,
          },
        });
      });
  };
}

export function clearStores() {
  return (dispatch) => {
    dispatch({ type: CLEAR_STORES });
  };
}

export function clearStoreJson() {
  return (dispatch) => {
    dispatch({ type: CLEAR_STORE_JSON });
  };
}

export function showBopsError(productId, errorMessage) {
  return (dispatch) => dispatch({
    type: BOPS_ERROR,
    payload: { errorMessage },
    productId,
  });
}

export function setBopsErrorForReplenishment(productId) {
  return (dispatch) => dispatch({
    type: SET_BOPS_ERROR_FOR_REPLENISHMENT,
    productId,
  });
}

export function setBopsNoSelectionErrorForReplenishment(productId) {
  return (dispatch) => dispatch({
    type: BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT,
    productId,
  });
}

export function clearError(productId) {
  return (dispatch) => dispatch({
    type: CLEAR_ERROR,
    productId,
  });
}

export function clearOutfittingQLError(productId) {
  return (dispatch) => dispatch({
    type: CLEAR_OUTFITTING_QL_ERROR,
    productId,
  });
}

export function toggleFavorite(productInfo, isFavorite) {
  return (dispatch, getState) => {
    const state = getState();
    const PRODUCT_DETAIL_API_TIMEOUT = get(state, 'apiTimeouts.PRODUCT_DETAIL_API_TIMEOUT');
    const requestApi = httpWithLogging(state, PRODUCT_DETAIL_API_TIMEOUT);

    let action = 'removeFavorite';
    if (!isFavorite) {
      action = 'addFavorite';
      const userID = state?.session?.DYN_USER_ID;
      Auth.currentSession()
        .catch(() => {
        // eslint-disable-next-line no-unused-expressions, chai-friendly/no-unused-expressions
        localStorage?.setItem(`${userID}.favoritesAdded`, 'true');
        });
    }

    return requestApi.post(`${NMConfig.API_PRODUCT_DETAIL}/${action}/${productInfo.id}`,
      {
        cmosCatalogId: productInfo.cmosCatalogId,
        cmosItem: productInfo.cmosItem,
        isFavoritesPage: false,
      })
      .then((successResponse) => {
        dispatch({
          type: TOGGLE_FAVORITE,
          payload: successResponse.data.utagData.favoriteItemStatus,
          productId: productInfo.id,
        });

        dispatch({
          type: SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId: productInfo.cmosCatalogId,
            utagData: successResponse.data.utagData,
          },
        });
        reactCookie.save('SPCR', 0, { path: '/' });
      })
      .catch(() => dispatch({
        type: TOGGLE_FAVORITE,
        payload: 'error',
        productId: productInfo.id,
      }));
  };
}

export function setPDPPaginationContext(context) {
  return (dispatch) => {
    dispatch({
      type: SET_PDP_PAGINATION_CONTEXT,
      payload: context,
    });
  };
}

export function getProductUrlList(
  requestOptions = {},
  successHandler,
  failureHandler,
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      page = requestOptions.page,
      categoryId = requestOptions.categoryId,
      parentCategoryId = requestOptions.parentCategoryId,
      sortBy = requestOptions.sortBy,
      filterOptions = requestOptions.filterOptions,
      urlsOnly = 'true',
      fetchSize = requestOptions.fetchSize,
    } = requestOptions;

    const {
      session,
    } = state;

    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
      }),
    };

    const requestApi = httpWithLogging(state, get(state, 'apiTimeouts.PRODUCT_LIST_API_TIMEOUT', 8000));

    function getProductListApiURL(
      API_PRODUCT_LIST,
      categoryIdSP,
      parentCategoryIdSP,
      pageSP,
      sortBySP,
    ) {
      const sortByParams = sortBySP ? `&sortBy=${sortBy}` : '';
      return `${NMConfig.API_PRODUCT_LIST}?${stringify({
        categoryId: categoryIdSP,
        parentCategoryId: parentCategoryIdSP,
        page: pageSP,
        filterOptions,
        urlsOnly,
        fetchSize,
      })}${sortByParams}`;
    }

    const url = getProductListApiURL(
      NMConfig.API_PRODUCT_LIST,
      categoryId,
      parentCategoryId,
      page,
      sortBy,
    );
    requestApi.get(url, { headers })
      .then((successResponse) => {
        successHandler(successResponse.data);
      })
      .catch((error) => {
        failureHandler(error);
      });
  };
}

export function setActiveStyleBoard(id) {
  return (dispatch, getState) => {
    const state = getState();
    return new Promise((resolve) => {
      const recsList = get(state, 'productCatalog.outfitting.outfittingRecs.csbList');
      const minifiedProductResponse = get(state, 'productCatalog.outfitting.minifiedProductResponse');
      const availableProduct = minifiedProductResponse?.filter(
        (el) => el.styleBoardId === id);
      if (availableProduct[0]) {
        dispatch({
          type: SET_ACTIVE_STYLE_BOARD,
          payload: id,
        });
      } else {
        dispatch({ type: SHOW_LOADING });
        getMinifiedProductResponseCall(recsList, id)(dispatch, getState);
      }
      return resolve();
    });
  };
}

export function sourceQuickLook(isEnabled) {
  return (dispatch) => dispatch({
    type: SOURCE_QUICK_LOOK,
    payload: isEnabled,
  });
}

export function sourceOutfittingQuickLook(isEnabled) {
  return (dispatch) => dispatch({
    type: SOURCE_OUTFITTING_QUICK_LOOK,
    payload: isEnabled,
  });
}

export function resetQLProduct(product) {
  return (dispatch) => dispatch({
    type: RESET_QUICK_LOOK_PRODUCT_PDP,
    payload: product,
  });
}

export function showQLModalWindow(productId) {
  const data = {
    productId,
  };

  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const {
      session,
    } = state;

    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const {
      promos,
    } = JSON.parse(get(session, 'dt_personalize_data', '{}'));

    const compositeEnabled = get(state, 'abTestsOpt.pdpfoundation.variation', 'pdp_foundation_off') === 'pdp_foundation_on';

    const uriPath = (compositeEnabled)
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP_COMPOSITE}/${productId}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;

    const requestURI = promos && promos.length
      ? `${uriPath}?personalizedPromos=${promos}`
      : `${uriPath}`;

    const requestApi = httpWithLogging(state);
    const dprToggle = get(state, 'toggles.CLOUDINARY_DPR', false);
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        dispatch({
          type: RESOLVED_QUICK_LOOK_PRODUCT_PDP,
          payload: { ...successResponse.data },
          ftrTgls: { dprToggle },
        });
        const product = successResponse.data || {};
        if (product.isGroup) {
          dispatch({
            type: 'RESOLVED_GROUP',
            payload: successResponse.data,
            ftrTgls: { dprToggle },
          });
        }
        if (!pageUtils.isMobile()) {
          dispatch(openModal({
            type: 'QLOutfittingSummary',
            props: data,
            className: 'ql-product-summary-pdp ql-optimized',
          }));
        }
      })
      .catch(() => {
        dispatch(openModal({ type: 'ProductListErrorModal' }));
      });
  };
}

export function increasePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value + 1}`)(dispatch);
    }
    dispatch({
      type: INCREASE_PRODUCT_QL_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function decreasePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value - 1}`)(dispatch);
    }
    dispatch({
      type: DECREASE_PRODUCT_QL_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function updatePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value}`)(dispatch);
    }
    dispatch({
      type: UPDATE_PRODUCT_QL_QUANTITY,
      payload: value,
      productId,
    });
  };
}

export function setPickupStore(storeNumber) {
  return {
    type: SET_PICKUP_STORE,
    payload: storeNumber,
  };
}
